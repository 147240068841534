<template>
  <div>
     <base-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :showFooter="false"
      title="预览"
    >
    <div class="dowlaodbtn">
      <base-button icon="iconfont iconxiazai" label="文件下载" @click="dowload()"></base-button></div>
      <pre-view :count="count" :fileId="fileId" :fileType="fileType"></pre-view>
      <template slot="footer">
        <base-button
          label="关 闭"
          type="default"
          @click="dialogVisible = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import preView from '@/components/pre-view/pre-view.vue'
export default {
  components: {
    BaseDialog, preView, BaseButton
  },
  props: {
    previsible: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    fileId: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  computed: {
    dialogVisible: {
      get () {
        return this.previsible
      },
      set (val) {
        this.$emit('update:previsible', val)
      }
    }
  },
  methods: {
    dowload () {
      this.$emit('dowload', '')
    }
  }
}
</script>
<style lang="scss" scoped>
.dowlaodbtn{
  padding: 5px 10px;
  text-align: right;
}
</style>
